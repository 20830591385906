import { Container } from '@actovos-consulting-group/ui-core';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';

const ContentFulPage = ({ pageContext: { content, title } }) => (
  <Layout>
    <SEO title={title} description={title} />
    <Container>{renderRichText(content)}</Container>
  </Layout>
);

export default ContentFulPage;
